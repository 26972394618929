.products {
    max-width: 100vw;

    .list-header {
        margin:0rem 0.5rem;
    }

    .search-term {
        .search-input {
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 4px;
            padding: 0rem 10px;
            width: 100%;
            outline: none !important;
        }
    }

    .product-list {
        display: flex;
        width:100%;
        flex-wrap: wrap;

        .product {
            position: relative;
            width: calc(100% / 15);
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 1500px) {
                width: calc(100% / 10);
            }

            @media only screen and (max-width: 1000px) {
                width: calc(100% / 8);
            }

            @media only screen and (max-width: 1000px) {
                width: calc(100% / 5);
            }

            @media only screen and (max-width: 500px) {
                width: calc(100% / 3);
            }

            .btn-hide {
                position: absolute;
                right: 0.5rem;
                border:0px;
            }

            .product-name {
                padding: 0rem 0.5rem;
                font-size:10px;
                height: 2.7rem;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-decoration: none;
                word-break: break-all;
            }
            .product-image {
                width: 100%;
                padding: 0rem 0.5rem;
                height: 0;
                padding-bottom: calc(100% - 0.5rem);
                
                img {
                    width: 100%;
                }
            }

            .product-time {
                font-size: 10px;
                display: flex;
                justify-content: center;
            }

            .btn-details {
                margin: 0rem 0.5rem 0.5rem;
                line-height: 1rem;
                padding: 0px; 

                @media only screen and (max-width: 1000px) {
                    line-height: 1.5rem;
                }
            }

            
        }
    }
    
    .list-footer {
        margin: 0.1rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .pagination {
                margin-bottom: 0rem;
        
                :global(.page-link) {
                    padding: 0.3rem;
                }
            }
        
        .btn-hide-all {
            line-height: 1rem;

            @media only screen and (max-width: 1000px) {
                    line-height: 1.5rem;
                }
        }
    }



    
}