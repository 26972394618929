.reviews {
    max-width: 100vw;

    .list-header {
        padding: 0rem 0.25rem 0.25rem;

        >div {
            border: 1px solid #cccccc;
            padding: 0rem 0.25rem;

            &:not(:last-child) {
                margin-bottom: 0.2rem;
            }
        }

        .search-term {
            .search-input {
                border: none;
                width: 100%;
                outline: none !important;
            }
        }
    }

    .review-list {
        display: flex;
        flex-direction: column;

        .review-row {
            padding: 0.2rem;
            background-color: #f4f4f4;
            display: flex;
            border: 1px solid #c1c1c1;

            &:nth-child(2n) {
                background-color: white;
            }

            .product-image {
                border: 1px solid #c1c1c1;
                background-color: white;
                width: 4.4rem;
                height: 4.4rem;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                overflow: hidden;


                img {
                    height: 4.4rem;
                }
            }
            
            .product-details {
                padding-left: 0.5rem;
                font-size: 12px;
                flex-grow: 1;
                    

                .inner-details {
                    display: flex;
                    flex-direction: row;
                    margin-top: 0.25rem;

                    .details {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .details-row {
                            display: flex;
                            align-items: center;

                            .data {
                                margin-right: 0.5rem;
                            }

                            .admin-controls {
                                display: flex;
                            }
                           
                        }
                    }
                    .actions {
                        display: flex;
                        align-items: center;

                        .mark-as-submitted {
                            margin-right: 0.25rem;
                            height: 2rem;
                            padding: 0 0.5rem;
                        }
                    }
                }
            }
            .product-actions {
                margin-left: 0.5rem;
            }
        }
    }

    .list-footer {
        margin: 0.5rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination {
        margin-bottom: 0rem;

        :global(.page-link) {
            padding: 0.3rem;
        }
    }
    
}