.product {
    margin: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
        width: 15rem;
    }

    .history {
        margin-top: 1rem;
    }
}